import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import useSingleFetch from "../hooks/useSingleFetch";
import Loader from "../components/Loader";
import moment from "moment";
import useFetch from "../hooks/useFetch";
import BackToTopButton from "../components/BackToTopButton";

const Member = () => {
  const navigate = useNavigate();

  const { data } = useSingleFetch("users", "fkxkksUoTaSGhc9P9JQRpHNVRn22");
  const { allPosts, loadingPosts } = useFetch("posts");
  const dropRef = useRef(null);
  const [search, setSearch] = useState("");
  const [hidden, setHidden] = useState(true);
  const [active, setActive] = useState(null);

  const searchData =
    allPosts &&
    allPosts?.filter(
      (post) =>
        post.title.toLowerCase().includes(search.toLowerCase()) +
        post.content.toLowerCase().includes(search.toLowerCase()) +
        post.id.toLowerCase().includes(search.toLowerCase())
    );

  useEffect(() => {
    const clickOutside = (e) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setHidden(true);
      }
    };
    return () => window.addEventListener("mousedown", clickOutside);
  }, [dropRef]);

  useEffect(() => {
    //scroll to top if searching keyword
    const scrollTop = () => {
      window.scrollTo(0, 0);
    };
    scrollTop();
  }, [searchData]);

  useEffect(() => {
    //default scroll to top when page loads
    const scrollTop = () => {
      window.scrollTo(0, 0);
    };
    scrollTop();
  }, []);

  return (
    <>
      {loadingPosts ? (
        <Loader />
      ) : (
        <div className="w-full justify-between p-2 mx-auto realtive">
          <div className="grid grid-cols-1  gap-4">
            <div className="col-span-1">
              <div className="md:max-w-[35rem] flex items-center text-green-400 shadow-md rounded-lg bg-black sticky top-0 md:top-0 h-10 text-sm mx-auto mb-5">
                <input
                  type="text"
                  placeholder={`Filter posts by keywords`}
                  className="pl-3 bg-transparent outline-none w-full "
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {search ? (
                  <i
                    className={`bi bi-x absolute right-0 px-3 cursor-pointer`}
                    onClick={() => setSearch("")}
                  ></i>
                ) : (
                  <i className="bi bi-search absolute right-0 px-3"></i>
                )}
              </div>

              {allPosts && !searchData.length > 0 ? (
                <div className="text-zinc-500 text-center">No results</div>
              ) : (
                allPosts &&
                searchData?.map((post, i) => (
                  <div
                    key={i}
                    onClick={() => navigate(`/post/${post.id}`)}
                    className={`cursor-pointer md:max-w-[40rem] lg:max-w-[35rem] mb-1 mx-auto p-2 md:p-5 bg-white rounded-lg border border-gray-200
                      hover:bg-slate-50 transition-all ${
                        allPosts[allPosts.length - 1] ? "" : ""
                      }
                    border-zinc-100 ${active === post.id ? "bg-zinc-100" : ""}`}
                  >
                    <div className="md:p-0">
                      <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2 flex-wrap">
                          <img
                            src={data.userImg}
                            alt={data.displayName}
                            className="cursor-pointer h-10 w-10 object-cover rounded-full"
                            onClick={() => navigate("/about")}
                          />
                          <span className="text-xs text-zinc-500 pb-1">
                            {data.displayName} &#xb7;{" "}
                            {moment(post.created).fromNow()}
                          </span>
                        </div>

                        <div
                          className={`flex items-center flex-wrap gap-1 ${
                            post.tags ? "" : "hidden"
                          } `}
                        >
                          {post.tags &&
                            post.tags?.map((tag, i) => (
                              <span
                                key={i}
                                className="text-xs border border-gray-400 rounded-full px-2 text-green-500 font-medium"
                              >
                                {tag}
                              </span>
                            ))}
                        </div>

                        <div
                        // className="cursor-pointer"
                        // onClick={() => navigate(`/post/${post.id}`)}
                        >
                          <div>
                            <h5 className="font-semibold text-gray-800 text-lg leading-6 line-clamp-3">
                              {post.title}
                            </h5>

                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(post.content),
                              }}
                              className={`leading-6 line-clamp-2 text-sm`}
                            ></div>
                          </div>

                          {/* {post.postImg && (
                            <img
                              src={post.postImg}
                              alt={post.title}
                              className="w-full h-60 rounded-lg object-cover my-3"
                            />
                          )} */}
                        </div>
                        {/* <div className="flex items-center gap-2">
                          <button className="text-sm flex items-center gap-1">
                            <span>0</span>
                          
                            Comment
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}

      <BackToTopButton />
    </>
  );
};

export default Member;
